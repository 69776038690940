import { Injectable } from '@angular/core';
import {
  QueryTokenDto,
  ScheduleItemDto,
} from '@swagger/generated/humanresources/models';
import { MyScheduleService } from '@swagger/generated/humanresources/services';

@Injectable({ providedIn: 'root' })
export class HrpMyScheduleService {
  /**
   * My schedules / Meine Zeitpläne/Kalender.
   */
  myScheduleListMySchedules() {
    return this.mySchedule.myScheduleListMySchedules();
  }

  /**
   * Add or update schedule item / Zeit-/Kalendereintrag hinzufügen oder ändern
   * Start, Stop and ScheduleItemType are required / Start, Stop und ScheduleItemType müssen angegeben werden.
   */
  myScheduleSaveMyScheduleItems(
    scheduleUId: string,
    scheduleItem: ScheduleItemDto[]
  ) {
    return this.mySchedule.myScheduleSaveMyScheduleItems({
      scheduleUId: scheduleUId,
      body: scheduleItem,
    });
  }

  /**
   * List my available schedule item types / Meine verfügbare Zeit-/Kalendereintragsarten.
   */
  myScheduleListMyAvailableScheduleItemTypes(
    scheduleUId: string,
    date: Date,
    startTime: string,
    stopTime: string
  ) {
    // Start und Stop Datum anhand übergebener Zeit erzeugen
    const { start, stop } = this.getDateByTime(date, startTime, stopTime);
    if (!start?.toISOString() || !stop?.toISOString()) return;

    return this.mySchedule.myScheduleListMyAvailableScheduleItemTypes({
      scheduleUId,
      body: [{ start: start?.toISOString(), stop: stop?.toISOString() }],
    });
  }

  /**
   * My schedule data / Eigener Mitarbeiterplan.
   */
  myScheduleGetMySchedule(queryToken: QueryTokenDto) {
    return this.mySchedule.myScheduleGetMySchedule({ body: queryToken });
  }

  /**
   * My schedule data / Eigener Mitarbeiterplan.
   * Hilfsmethode um das QueryToken mit einem Zeitraum zu befüllen
   */
  myScheduleGetMyScheduleByTime(startDate: Date, endDate: Date) {
    const queryToken: QueryTokenDto = {
      filter: {
        timespan: `"${startDate.toISOString()}"-"${endDate.toISOString()}"`,
      },
    };
    return this.myScheduleGetMySchedule(queryToken);
  }

  /**
   * Erzeugt anhand des übergebenen Datums und der übergebenen Zeit ein neues Datum
   * @param date Datum an dem die Zeit angehängt werden soll
   * @param startTime Startzeit im Format hh:mm
   * @param stopTime Endzeit im Format hh:mm
   * @returns {start: Date, stop: Date}
   */
  private getDateByTime(
    date: Date,
    startTime: string,
    stopTime: string
  ): { start: Date; stop: Date } {
    try {
      const start = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        Number(startTime.split(':')[0]),
        Number(startTime.split(':')[1])
      );
      const stop = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        Number(stopTime.split(':')[0]),
        Number(stopTime.split(':')[1])
      );
      return { start, stop };
    } catch (err) {
      console.error('Error creating date by time', err);
    }
    return { start: null, stop: null };
  }

  /**
   * Service für die Zeitplanung des angemeldeten Benutzers
   */
  constructor(private mySchedule: MyScheduleService) {}
}
