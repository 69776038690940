import { PlanGroupState } from "./plan-group/plan-group.state";
import { PlanState } from "./plan/plan.state";

export interface CalendarState {
    plan: PlanState;
    planGroup: PlanGroupState;
}

export const FEATURE_NAME = 'feature/calendar';
export const FEATURE_ACTION_PREFIX = 'Feature Calendar';
